import 'popper.js';
import 'bootstrap';

(function () {

  'use strict';

  Drupal.behaviors.helloWorld = {
    attach: function (context) {

    }
  };

})(jQuery, Drupal);






(function ($) {


  $(document).ready(function () {




    $('.header-hamburger-open').on('click', function(e) {
      $('.hamburger-content').css('top', '0');

    });
    $('.header-hamburger-mobile-open').on('click', function(e) {
      $('.hamburger-content').css('top', '0');

    });

    $('.header-hamburger-off').on('click', function(e) {
      $('.hamburger-content').css('top', '-100%');

    });




  });


})(jQuery);



